<template>
  <div>
    <client-only>
      <HgH2goSearch
        allowHotjarRecord
        :is-mobile="isMobile"
        :is-loading-destinations="isLoading"
        :destinations="suggestedDestinations"
        :search-url="searchUrl"
        :roomLabel="$t('amadeusPackageTourSearch.roomLabel')"
        :infoTextSingleRoom="$t('amadeusPackageTourSearch.infoTextSingleRoom')"
        :infoTextSingles="$t('amadeusPackageTourSearch.infoTextSingles')"
        :infoTextSingleRoomMultiple="
          $t('amadeusPackageTourSearch.infoTextSingleRoomMultiple')
        "
        :infoTextMultipleSingular="
          $t('amadeusPackageTourSearch.infoTextMultipleSingular')
        "
        :infoTextMultiplePlural="
          $t('amadeusPackageTourSearch.infoTextMultiplePlural')
        "
        :showRooms="true"
        :showMadeByGoogle="true"
        @onUpdateDestinationTerm="updateDestinationTerm"
        @onUpdateSearchParameters="updateSearchParameters"
        @onTriggerSearch="triggerSearch"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import HoliduDestinationAutosuggestQuery from '@/apollo/queries/ibe/HoliduDestinationAutosuggest.gql';
import HoliduSearchUrlQuery from '@/apollo/queries/ibe/HoliduSearchUrl.gql';

import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import { format } from 'date-fns';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    selectedDestination: null,
    isLoading: false,
    selectedDaterange: null,
    duration: null,
    selectedTravelers: { adults: 2 },
    geoLocation: null,
    destinationTerm: '',
    suggestedDestinations: [],
    orderedDestinations: [],
    searchUrl: '',
    errors: null
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  methods: {
    async updateSearchParameters(params) {
      this.selectedDestination = params.destination;
      this.selectedDaterange = params.dateRange;
      this.selectedTravelers = params.travelers || this.selectedTravelers;
      this.duration = this.$dateFns.differenceInDays(
        this.selectedDaterange.end,
        this.selectedDaterange.start
      );

      await this.getSearchUrl();
    },

    async getSearchUrl() {
      if (!this.selectedDaterange) return;
      if (!this.selectedTravelers) return;
      if (!this.selectedDestination) return;

      try {
        const { data } = await this.$apollo.query({
          query: HoliduSearchUrlQuery,
          variables: {
            input: {
              startDate: this.$dateFns.formatISO(this.selectedDaterange.start, {
                representation: 'date'
              }),
              endDate: this.$dateFns.formatISO(this.selectedDaterange.end, {
                representation: 'date'
              }),
              duration: this.duration,
              destination: {
                id: this.selectedDestination.id,
                pathname: this.selectedDestination.pathname,
                name: this.selectedDestination.name
              },
              numberOfAdults: this.selectedTravelers.adults,
              agesOfChildren: this.selectedTravelers.ages,
              numberOfBedrooms: this.selectedTravelers.rooms
            }
          }
        });
        this.searchUrl = data.holiduSearchUrl;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('Error', e);
      }
    },

    triggerSearch() {
      this.$gtm.push({
        event: 'H2G_search_used',
        destination: this.selectedDestination.name,
        period: `${format(
          this.selectedDaterange.start,
          'EE, dd.MM.yyyy'
        )} - ${format(this.selectedDaterange.end, 'EE, dd.MM.yyyy')}`,
        travellers: this.selectedTravelers
      });
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term;
      this.isLoading = true;
      this.debounce(this.queryDestinations, 300);
    },

    async queryDestinations() {
      if (this.destinationTerm === '') {
        this.isLoading = false;
        return;
      }
      try {
        const {
          data: { holiduDestinationAutosuggest }
        } = await this.$apollo.query({
          query: HoliduDestinationAutosuggestQuery,
          variables: {
            query: this.destinationTerm
          }
        });

        this.suggestedDestinations = holiduDestinationAutosuggest;
      } catch (e) {
        this.errors = e.graphQLErrors;
      } finally {
        this.isLoading = false;
      }
    },

    debounce(func, delay) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, delay);
    }
  },
  apollo: {
    holiduDestinationAutosuggest: {
      skip() {
        return !this.destinationTerm.length;
      },
      debounce: 300,
      query: HoliduDestinationAutosuggestQuery,
      variables() {
        return {
          query: this.destinationTerm
        };
      }
    }
  }
};
</script>
